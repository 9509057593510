import React from 'react';
import { useMediaQuery, CssBaseline } from '@mui/material';
import DesktopComponent from './DesktopComponent';
import TabletComponent from './TabletComponent';
import MobileComponent from './MobileComponent';

const App = () => {
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1199px)');
  const isMobile = useMediaQuery('(max-width:599px)');
  const isPortrait = useMediaQuery('(orientation: portrait)');

  return (
    <div>
      <CssBaseline />
      {isDesktop && <DesktopComponent />}
      {isTablet && !isPortrait && <TabletComponent />}
      {(isMobile || (isTablet && isPortrait)) && <MobileComponent />}
    </div>
  );
};

export default App;
