import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Backdrop, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import textImage from './assets/text.png';
import adsImage from './assets/ads.png';
import bookImage from './assets/book.png';
import startImage from './assets/star.png';
import flyImage from './assets/fly.png';
import logo from './assets/logo.png';

const App = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    nationality: '',
    city: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const { name, email, nationality, city } = formData;
      if (name && email && nationality && city) {
        setOpen(true);
      } else {
        alert('Please fill out all fields.');
      }
    }
  };

  const handleClose = async (confirm) => {
    if (confirm) {
      setOpen(false);
      setLoading(true);
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('nationality', formData.nationality);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('image', selectedImage);

        const response = await fetch('https://sadia.symloop.com/api/information', {
          method: 'POST',
          body: formDataToSend,
        });

        if (response.ok) {
          toast.success('Information uploaded successfully!');
          setFormData({
            name: '',
            email: '',
            nationality: '',
            city: '',
          });
          setSelectedImage(null);
          setSuccessDialogOpen(true);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message || 'Something went wrong'}`);
        }
      } catch (error) {
        console.error('Error uploading the information', error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    } else {
      setOpen(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <Box sx={{ height: '130vh', display: 'flex', flexDirection: 'column', margin: 0, padding: 0, overflowY: 'auto' }}>
      <Box 
        sx={{ 
          position: 'relative', 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          margin: 0,
          padding: 0
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            width: 120,
            height: 120,
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={logo} 
            alt="circle"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
      <AppBar 
        position="static" 
        sx={{ 
          bgcolor: 'white', 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' 
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" color="black"></Typography>
        </Toolbar>
      </AppBar>
      <Box 
        sx={{ 
          flexGrow: 1, 
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          padding: 0,
          '@media (min-width: 600px)': {
            flexDirection: 'row',
          }
        }}
      >
        <Box 
          sx={{ 
            flex: 1, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            padding: 2,
            position: 'relative'
          }}
        >
          <img src={textImage} alt="Text" style={{ position: 'absolute', top: '15%', left: '15%', height: "25%" }} />
          <img src={adsImage} alt="Ads" style={{ position: 'absolute', top: '40%', left: '10%', height: "50%" }} />
          <img src={bookImage} alt="Book" style={{ position: 'absolute', bottom: 0, right: 0 ,top:"80%",height: "10%" }} />
          <img src={startImage} alt="Start" style={{ position: 'absolute', top: '35%', right: 0,height:50 }} />
          <img src={flyImage} alt="Fly" style={{ position: 'absolute', top: '25%', left: 0 ,height:40}} />
        </Box>
      </Box>
      
      <Box 
        sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
          padding: 2,
          '@media (min-width: 600px)': {
            padding: 1,
          },
          '@media (min-width: 900px)': {
            padding: 3,
          }
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="raised-button-file"></label>
        <TextField 
          variant="outlined" 
          placeholder="NAME" 
          name="name"
          value={formData.name}
          onChange={handleChange}
          InputProps={{
            style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
          }}
          sx={{ 
            backgroundColor: 'transparent',
            borderRadius: 5,
            marginBottom: 2, 
            width: '80%', 
            border: '2px dashed white', 
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '& input::placeholder': {
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center'
              },
            }
          }} 
        />
        <TextField 
          variant="outlined" 
          placeholder="EMAIL" 
          name="email"
          value={formData.email}
          onChange={handleChange}
          InputProps={{
            style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
          }}
          sx={{ 
            backgroundColor: 'transparent',
            borderRadius: 5,
            marginBottom: 2, 
            width: '80%', 
            border: '2px dashed white', 
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '& input::placeholder': {
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center'
              },
            }
          }} 
        />
        <TextField 
          variant="outlined" 
          placeholder="PHONE NUMBER" 
          name="nationality"
          value={formData.nationality}
          onChange={handleChange}
          InputProps={{
            style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
          }}
          sx={{ 
            backgroundColor: 'transparent',
            borderRadius: 5,
            marginBottom: 2, 
            width: '80%', 
            border: '2px dashed white', 
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '& input::placeholder': {
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center'
              },
            }
          }} 
        />
        <TextField 
          variant="outlined" 
          placeholder="CITY" 
          name="city"
          value={formData.city}
          onChange={handleChange}
          InputProps={{
            style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
          }}
          sx={{ 
            backgroundColor: 'transparent',
            borderRadius: 5,
            width: '80%', 
            border: '2px dashed white', 
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '& input::placeholder': {
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center'
              },
            }
          }} 
        />
        <Box 
          sx={{ 
            backgroundColor: 'white', 
            marginTop: 2,
            color: 'black', 
            fontWeight: 'bold', 
            textAlign: 'center', 
            borderRadius: 5, 
            width: '75%', 
            marginBottom: 2,
            padding: 2,
            fontSize: '1.8rem', 
            cursor: 'pointer' 
          }}
          onClick={() => document.getElementById('raised-button-file').click()}
        >
          Upload Receipt
        </Box>
      </Box>

      {/* Footer Container */}
      <Box 
        sx={{ 
          bgcolor: 'white', 
          width: '100%', 
          p: 2, 
          mt: 4, 
          textAlign: 'center', 
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" color="black" sx={{ mb: 1, fontWeight: 'bold' }}>
          For inquiries, send us an email at 
          <a 
            href="mailto:contact@winwithsadia.com" 
            style={{ color: 'red', textDecoration: 'none', marginLeft: '5px' }}
          >
            contact@winwithsadia.com
          </a>
        </Typography>
        <Typography variant="body2" color="black" sx={{ fontWeight: 'bold' }}>
          *This offer is only valid from 16th August 2024 to 15th September 2024
        </Typography>
      </Box>

      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Upload Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to upload the receipt?
          </DialogContentText>
          {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '100%' }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} sx={{ fontFamily: 'MyFont', fontWeight: 'bold' }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
      >
        <DialogTitle>Thank You for Your Submission!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We have received your receipt and information. Your participation is currently under review to ensure it meets our terms and conditions. You will receive a confirmation shortly. Stay Tuned!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="bottom-center" />
    </Box>
  );
};

export default App;
